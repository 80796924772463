export type CategoryId =
  | 'vase'
  | 'pedestal'
  | 'vessel'
  | 'flowerpot'
  | 'basket'
  | 'candle-holder'
  | 'lantern'
  | 'charger-plate'
  | 'frame-number'
  | 'sign'
  | 'easel'
  | 'crate'
  | 'backdrop'
  | 'textile'
  | 'lighting'

export const categoryNameToIdMap: { [key: string]: CategoryId } = {
  wazon: 'vase',
  stojak: 'pedestal',
  naczynie: 'vessel',
  donica: 'flowerpot',
  kosz: 'basket',
  świecznik: 'candle-holder',
  lampion: 'lantern',
  podtalerz: 'charger-plate',
  'ramka-numerek': 'frame-number',
  'tablica-znak': 'sign',
  sztaluga: 'easel',
  skrzynka: 'crate',
  'bramka-stelaż': 'backdrop',
  tkanina: 'textile',
  oświetlenie: 'lighting',
}

export interface Category {
  categoriesIds: CategoryId[]
  categoryDisplayName: string
  categoryDescriptionHtml: string
  titleMain: string
  titleSecondary?: string
  slug: string
  imageName: string // corresponds with a file query for 'images/categories/imageName'
  imageAlt: string
  order: number
}

export const defaultCategory: Category = {
  categoriesIds: Object.values(categoryNameToIdMap),
  categoryDisplayName: 'Wszystkie dekoracje',
  categoryDescriptionHtml:
    '<p>W asortymencie wypożyczalni znajdziesz artykuły dekoracyjne, które będą świetnym uzupełnieniem Twojego przyjęcia. Dostępne są akcesoria do dekoracji stołów takie jak naczynia lub stojaki na kwiaty, świeczniki, podtalerze czy ramki. Natomiast do aranżacji wnętrza sprawdzi się klimatyczne oświetlenie dekoracyjne, ozdobne bramki lub tkaniny.</p>',
  titleMain: 'Dekoracje ślubne',
  titleSecondary: 'i eventowe',
  slug: 'dekoracje-slubne-i-eventowe',
  imageName: 'wazony-stojaki',
  imageAlt: 'Dekoracje ślubne i eventowe',
  order: 0,
}

export const categories: Category[] = [
  {
    categoriesIds: ['vase', 'pedestal', 'vessel', 'flowerpot', 'basket'],
    categoryDisplayName: 'Wazony, stojaki i naczynia',
    categoryDescriptionHtml: `<p>Wazony, stojaki i naczynia są nośnikami dla dekoracji kwiatowych na różnego rodzaju przyjęciach. Mogą to być szklane wazony, wazoniki, butelki i buteleczki z przezroczystego lub kolorowego szkła pasujące do każdego stylu. Kompozycje kwiatowe można ułożyć także w ceramicznych lub metalowych wazach, misach, donicach nadających bardziej wyraźnego charakteru. Tego typu naczynia, są idealne do stworzenia naturalnych kompozycji w siatce lub w kenzanie.</p>
    
    <p>Nośnikami dla wysokich kompozycji są metalowe współczesne kwietniki, stylizowane złote świeczniki oraz wysokie wazony i kielichy.</p>
    `,
    titleMain: 'Wazony',
    titleSecondary: 'i stojaki',
    slug: 'wazony-stojaki-naczynia',
    imageName: 'wazonyStojaki',
    imageAlt: 'Kategoria dekoracji: Wazony, stojaki i naczynia',
    order: 1,
  },
  {
    categoriesIds: ['frame-number'],
    categoryDisplayName: 'Ramki i numerki',
    categoryDescriptionHtml:
      '<p>Ramki na przyjęciu sprawdzą się jako nośnik na numerację stołów lub tabliczka informacyjna przy księdze gości czy barze. Dostępne są trzy podstawowe kolory ramek: złoty, srebrny i biały, które wpiszą się w eleganckie stylizacje. Natomiast w mniej zobowiązujących stylowo wnętrzach dobrze sprawdzą się drewniane tabliczki z gotową numeracją.</p>',
    titleMain: 'Ramki',
    titleSecondary: 'i numerki',
    slug: 'ramki-numery-stolow',
    imageName: 'ramkiNumeryStolow',
    imageAlt: 'Kategoria dekoracji: Ramki i numery stołów',
    order: 2,
  },
  {
    categoriesIds: ['candle-holder', 'lantern'],
    categoryDisplayName: 'Świeczniki i lampiony',
    categoryDescriptionHtml:
      '<p>Świeczniki i lampiony stanowią idealne uzupełnienie dekoracji kwiatowych, wprowadzając klimatyczną atmosferę przy stołach. Mogą to być różnego rodzaju świeczniki na stoły w formie niskich szklaneczek na tealighty czy szklanych kielichów na nóżce. Dostępne są również mosiężne i szklane świeczniki na cienkie świece. Ponadczasowe szklane tuby świetnie sprawdzą się jako świeczniki na stołach lub wykorzystane do dekoracji ceremonii ślubnej wzdłuż drogi do ołtarza.</p>',
    titleMain: 'Świeczniki',
    titleSecondary: 'i lampiony',
    slug: 'swieczniki-lampiony',
    imageName: 'swiecznikiLampiony',
    imageAlt: 'Kategoria dekoracji: Świeczniki i lampiony',
    order: 3,
  },
  {
    categoriesIds: ['sign', 'easel', 'crate'],
    categoryDisplayName: 'Drewniane akcesoria',
    categoryDescriptionHtml:
      '<p>Drewniane akcesoria to różnego rodzaju elementy dekoracji wykonane z drewna lub sklejki. Są to m.in. skrzynki na kwiaty i numeracja stołów. Duże ramy, tablice, kierunkowskazy ślubne i sztalugi sprawdzą się jako elementy przydatne do komunikacji z gośćmi na przyjęciu.</p>',
    titleMain: 'Drewniane',
    titleSecondary: 'akcesoria',
    slug: 'drewniane-akcesoria',
    imageName: 'drewnianeAkcesoria',
    imageAlt: 'Kategoria dekoracji: Akcesoria drewniane',
    order: 4,
  },
  {
    categoriesIds: ['backdrop'],
    categoryDisplayName: 'Bramki i stelaże',
    categoryDescriptionHtml:
      '<p>Bramki i stelaże to przestrzenne, ozdobne konstrukcje, które świetnie sprawdzą się jako baza do aranżacji ścianki za Parą Młodą oraz jako tło ceremonii ślubnej w plenerze. Mniejsze stojaki mogą być podstawą dekoracji ścianki do zdjęć lub rozpiski z listą gości.</p>',
    titleMain: 'Bramki',
    titleSecondary: 'i stelaże',
    slug: 'bramki-stelaze',
    imageName: 'bramkiStelaze',
    imageAlt: 'Kategoria dekoracji: Bramki i stelaże',
    order: 5,
  },
  {
    categoriesIds: ['charger-plate'],
    categoryDisplayName: 'Podtalerze',
    categoryDescriptionHtml:
      '<p>Szklane lub plastikowe ozdobne talerze podkreślą styl aranżacyjny przyjęcia. Stanowią elegancki i szykowny element dekoracji na stole zamiast typowej białej zastawy, która prezentuje się dość monotonnie na białym obrusie. Podtalerze są wykorzystywane jako podkładka dla właściwej zastawy, przez co chronią obrus przed zabrudzeniami.</p>',
    titleMain: 'Podtalerze',
    slug: 'podtalerze',
    imageName: 'podtalerze',
    imageAlt: 'Kategoria dekoracji: Podtalerze',
    order: 6,
  },
  {
    categoriesIds: ['textile'],
    categoryDisplayName: 'Tkaniny, bieżniki',
    categoryDescriptionHtml:
      '<p>Tkaniny i tekstylia wykorzystane w dekoracjach za stosunkowo niską kwotę tworzą efektowną aranżację we wnętrzu. Mogą to być szyfonowe bieżniki na stoły w najbardziej modnych kolorach, a także tkaniny przydatne do różnego rodzaju podwieszeń na ścianki czy do dekoracji ceremonii plenerowej.</p>',
    titleMain: 'Tkaniny',
    slug: 'tkaniny',
    imageName: 'tkaniny',
    imageAlt: 'Kategoria dekoracji: Tkaniny',
    order: 7,
  },
  {
    categoriesIds: ['lighting'],
    categoryDisplayName: 'Oświetlenie dekoracyjne',
    categoryDescriptionHtml:
      '<p>Dekoracyjne światełka ledowe tzw. “fairy lights” idealnie sprawdzą się do stworzenia kameralnej atmosfery na przyjęciu. Po zmierzchu wprowadzą delikatne i klimatyczne oświetlenie całej sali, samego parkietu lub stołu Pary Młodej.</p>',
    titleMain: 'Oświetlenie',
    titleSecondary: 'dekoracyjne',
    slug: 'oswietlenie-dekoracyjne',
    imageName: 'oswietlenieDekoracyjne',
    imageAlt: 'Kategoria dekoracji: Oświetlenie dekoracyjne',
    order: 8,
  },
]
