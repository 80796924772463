import { navigate } from 'gatsby'
import { useEffect } from 'react'

import { defaultCategory } from 'data/categories'

const DecorationsIndexPage = () => {
  // redirect "/dekoracje/" to default category view
  // client-only redirect should be supported by server-side redirect
  useEffect(() => {
    navigate(`/dekoracje/${defaultCategory.slug}`)
  }, [])

  return null
}

export default DecorationsIndexPage
